import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import moment from 'moment'
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator'
import {mapState} from 'vuex'
import {extend, ValidationProvider} from 'vee-validate'
import GtrStorage from '@/modules/common/services/storage.service'

extend('atLeastOneField', {
  validate(value, {fieldGroupClass}: Record<string, any>) {
    const fields: NodeListOf<HTMLInputElement> = document.querySelectorAll('.' + fieldGroupClass + ' input')

    function conditions(field: HTMLInputElement) {
      if (field.type === 'checkbox' || field.type === 'radio') {
        return field.checked
      }
      return field.value && field.value !== '0'
    }

    for (let i = 0; i < fields.length; i++) {
      if (conditions(fields[i])) {
        return true
      }
    }
    return false
  },
  params: ['fieldGroupClass', 'fieldGroupLabel'],
  message: 'At least one field for "{fieldGroupLabel}" must have a value'
});

@Component({
  name: 'GtrLeadsOrder',
  computed: {
    ...mapState('leads', ['form', 'lr_order_fees', 'finishedPayment']),
    ...mapState('auth', ['participant', 'needAuthField']),
    ...mapState('payment', ['stripe_token'])
  },
  components: {
    StripeCard: () => import('@/modules/common/components/stripe/stripe-card/stripe-card.vue'),
  }
})
export default class GtrLeadsOrder extends Vue {
  @Prop()
  design: any

  @Prop()
  option_groups: any

  @Prop()
  allContent: any

  form!: Record<string, any>

  lr_order_fees!: Record<string, any>

  stripe_token!: Record<string, any>

  participant!: Record<string, any>

  @Ref()
  deviceQuantities!: Array<InstanceType<typeof ValidationProvider>>

  get areLrDeviceSelectionsInvalid() {
    const lrDevices = this.$data.formData.find(field => field.field === 'lr_devices')
    if (!lrDevices) return false
    const proscanner = lrDevices.options.find(option => option.name === 'ProScanner')
    const lrApp = lrDevices.options.find(option => option.name === 'Lead Retrieval Mobile App')
    const editDataProscannerQty = proscanner ? this.$data.editFormData[proscanner.uuid] : 0
    const editDataAppQty = lrApp ? this.$data.editFormData[lrApp.uuid] : 0
    const lrActive = this.$data.formData.find(field => field.field === 'lr_activations')
    if (this.use_legacy_leads_pricing) {
      return (proscanner?.qty || 0) <= 0 && (lrApp?.qty || 0) <= 0 && editDataProscannerQty === 0 && editDataAppQty === 0
    } else {
      return (proscanner?.qty || 0) <= 0 && this.$data.activationsQuantityUuid === '' && editDataProscannerQty === 0 && lrActive.current_value === null
    }
  }

  get confirmationVerbiage() {
    return this.$props?.allContent?.data?.lr_settings?.orders_confirmation_page_verbiage ?? ''
  }

  get use_legacy_leads_pricing(): boolean {
    return this.$props?.allContent?.data?.orders_settings?.use_legacy_leads_pricing ?? false
  }

  get phone_number_mask_enabled(): boolean {
    return this.$props?.allContent?.data?.orders_settings?.phone_number_mask_enabled ?? true
  }

  get currentPricingTierUuid() {
    return this.$props?.allContent?.data?.tier_orders?.uuid ?? null
  }

  get earlyBirdPricing() {
    for (const tier of this.$props?.allContent?.data?.lr_tiers) {
      if (tier.name === 'Early Bird') {
        const earlyBird: any = {}
        earlyBird.uuid = tier.uuid
        for (const uuid in tier.option_specific_dates) {
          earlyBird[uuid] = {}
          earlyBird[uuid].start = this.convertUtcStringToDate(tier.option_specific_dates[uuid].date_start)
          earlyBird[uuid].end = this.convertUtcStringToDate(tier.option_specific_dates[uuid].date_end)
        }
        return earlyBird
      }
    }
    return {}
  }

  get lrStatus(): string {
    return this.participant?.participant_data?.lr_status || ''
  }

  get isStatusComplete(): boolean {
    return this.lrStatus === 'Complete'
  }

  get isMobileAppSelected(): boolean {
    const form_data = this.$data.formData
    for (let i = 0; i < form_data.length; i++) {
      const testField = form_data[i]
      if (testField.field) {
        if (this.use_legacy_leads_pricing) {
          if (testField.field === 'lr_devices' && Array.isArray(testField.options)) {
            for (let j = 0; j < testField.options.length; j++) {
              const option = testField.options[j]
              if (option.name === 'Lead Retrieval Mobile App') {
                if (option.qty > 0) {
                  return true
                }
              }
            }
          }
        } else {
          if (testField.field === 'lr_activations' && Array.isArray(testField.options)) {
            if (testField.current_value) {
              return true
            }
          }
        }
      }
    }
    return this.$data.activationsQuantityUuid !== ''
  }

  get isIosSelected(): boolean {
    const form_data = this.$data.formData

    for (let i = 0; i < form_data.length; i++) {
      const testField = form_data[i]
      if (testField.field) {
        if (testField.field === 'lr_which_os' && Array.isArray(testField.current_value)) {
          if (testField.current_value.includes('iOS')) {
            return true
          }
        }
      }
    }

    return false
  }

  get stripeKey() {
    return this?.paymentForm?.processorData?.tokens?.stripe_key || ''
  }

  /**
   * COMPUTED
   */

  get eventIdentifier() {
    return this.$route.params.event_identifier
  }

  get currentPage() {
    return parseInt(this.$route.params.pagenum)
  }

  get previousPage() {
    if ((this.currentPage - 1) >= 0) {
      return this.currentPage - 1
    }
    return ''
  }

  get viewableRegPages() {
    if (this.form) {
      return this.form.viewable_reg_pages
    }
    return []
  }

  get currentRegPages() {
    if (this.form) {
      return this.form.current_reg_pages
    }
    return []
  }

  get lastViewableRegPage() {
    if (this.viewableRegPages) {
      return this.viewableRegPages[this.viewableRegPages.length - 1]
    }
    return []
  }

  get nextBtnText() {
    if (this.isPaymentPage) {
      return 'Submit'
    }
    return 'Next'
  }

  get isPaymentPage() {
    if (this.form) {
      return this.form.pageData?.paymentForm ? true : false
    }
    return false
  }

  get isReceiptPage() {
    if (this.form) {
      return this.form.pageData.page_type === 'RECEIPT' ? true : false
    }
    return false
  }

  get paymentForm() {
    if (this.form) {
      return this.form.pageData.paymentForm
    }
    return null
  }

  get paymentWidget() {
    const pageFields = this.$data.formData
    for (let i = 0; i < pageFields.length; i++) {
      const field = pageFields[i]
      if (field.type === 'payment') {
        return field.widget_id
      }
    }
    return null
  }

  get stripeIntent() {
    if (this.stripe_token) {
      return this.stripe_token.tokenData.stripeIntentSecret
    }
    return ''
  }

  get transaction_type() {
    const pageFields = this.$data.formData
    for (let i = 0; i < pageFields.length; i++) {
      const field = pageFields[i]
      if (field.type === 'payment') {
        return field.transaction_type
      }
    }
    return null
  }

  get currencySymbol() {
    if (this.lr_order_fees) {
      return this.lr_order_fees.currency
    }
    return ''
  }

  get subtotalTax() {
    if (this.lr_order_fees) {
      if (typeof this.lr_order_fees.subtotal_tax === 'string') {
        return parseFloat(this.lr_order_fees.subtotal_tax)
      } else if (typeof this.lr_order_fees.subtotal_tax === 'number') {
        return this.lr_order_fees.subtotal_tax
      }
    }
    return null
  }

  get balance() {
    if (this.lr_order_fees) {
      if (typeof this.lr_order_fees.balance === 'string') {
        return parseFloat(this.lr_order_fees.balance)
      } else if (typeof this.lr_order_fees.balance === 'number') {
        return this.lr_order_fees.balance
      }
    }
    return 0
  }

  get states() {
    const optionGroups = this.$props.option_groups
    const statesArr: any = []
    if (optionGroups) {
      for (let i = 0; i < optionGroups.length; i++) {
        const optionGroup = optionGroups[i]
        if (optionGroup.name === 'US States') {
          const states = optionGroup.options
          for (let j = 0; j < states.length; j++) {
            const state = states[j]
            statesArr.push(state.name)
          }
        }
      }
      return statesArr
    }
    return []
  }

  get countries() {
    const optionGroups = this.$props.option_groups
    const countriesArr: any = []
    if (optionGroups) {
      for (let i = 0; i < optionGroups.length; i++) {
        const optionGroup = optionGroups[i]
        if (optionGroup.name === 'Countries') {
          const countries = optionGroup.options
          for (let j = 0; j < countries.length; j++) {
            const country = countries[j]
            countriesArr.push(country.name)
          }
        }
      }
      return countriesArr
    }
    return []
  }

  get expirationYearsItems() {
    const arr: any = []
    /**
     * Fill the expirationYearsArray with this year and 15 years in the future
     * Must be a string for payment processing
     */
    let currentYear = parseInt(moment().year().toString())
    arr.push(currentYear.toString())
    for (let i = 0; i <= 15; i++) {
      arr.push((currentYear++).toString())
    }
    return arr
  }

  get currentLanguage() {
    // revisit later
    return 'en'
  }

  data() {
    return {
      activationsQuantityUuid: '',
      editFormData: {},
      paymentDialog: false,
      formProcessDialog: false,
      loading: false,
      dataLoaded: false,
      submitting: false,
      termsDialog: false,
      formData: [],
      optionGroupSelections: {},
      pageSubmissionData: {},
      creditSelected: false,
      stripe: false,
      elements: false,
      card: false,
      loadingStripe: false,
      stripeError: null,
      credit_card_number: '',
      credit_cvv: '',
      credit_expiration_month: '',
      credit_expiration_year: '',
      credit_first_name: '',
      credit_last_name: '',
      credit_address: '',
      credit_city: '',
      credit_state: '',
      credit_province: '',
      credit_zip: '',
      credit_country: '',
      expirationMonthsItems: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      provinces: [
        'AB',
        'BC',
        'MB',
        'NB',
        'NL',
        'NS',
        'ON',
        'PE',
        'QC',
        'SK',
        'NT',
        'NU',
        'YT'
      ]
    }
  }

  async mounted() {
    // first check for login_key
    if (this.$route.params.login_key) {
      // if exists, then login user -- might need to refactor this later
      this.$store.dispatch('common/showLoader', {value: true})
      const payload: any = {
        event_identifier: this.$route.params.event_identifier,
        pagenum: this.$route.params.pagenum,
        data: {
          login_key: this.$route.params.login_key,
        }
      }
      for (const field in this.$data.loginFieldData) {
        if (field === 'email') {
          payload.data[field] = this.$data.loginFieldData[field].toLowerCase() // format all email to lowercase
        } else {
          payload.data[field] = this.$data.loginFieldData[field]
        }
      }

      try {
        const response = await this.$store.dispatch('auth/login', payload)
        const {participant_data, viewable_reg_pages, next_page: response_next_page} = response.data
        const status = participant_data.status
        const next_page = status === 'Complete' ? viewable_reg_pages[0] : response_next_page.toString()
        this.$router.push('/' + payload.event_identifier + '/leads/order/' + next_page)
      } catch (error) {
        if (error.response.data.error_code === 'AUTH_FIELD_MISMATCH') {
          Container.get(Notification).error(error.response.data.error_message)
          return
        } else if (error.response.data.error_code === 'NEED_AUTH_FIELD') {
          this.$store.commit('auth/SET_NEED_AUTH_FIELD', error.response.data.field_needed)
        } else if (error.response.data.error_code === 'REGISTRATION_NOT_FOUND') {
          if (payload.participant_uuid) {
            Container.get(Notification).error('This registration ID does not exist.')
            return
          }
        } else {
          Container.get(Notification).error('Please specify a valid key.')
        }
      } finally {
        this.$store.dispatch('common/hideLoader')
      }
    }
    try {
      this.$store.dispatch('common/showLoader', {value: true})
      this.$data.loading = true
      const data = {
        event_identifier: this.$route.params.event_identifier,
        page_number: this.$route.params.pagenum
      }
      const response = await this.$store.dispatch('leads/getLeadsOrderForm', data)
      await this.fetchParticipant()
      if (response && Array.isArray(this.option_groups)) {
        this.loadFormData()
      }
      this.initializeEditFormData(this.$data.formData)
      if (this.lrStatus === 'Complete' && !Container.get(GtrStorage).getItem(`${this.eventIdentifier}_initial_lr_devices`)) {
        const lrField = this.$data.formData.filter((field: any) => field.field === 'lr_devices')
        if (lrField) {
          const optionsObj = lrField.pop()
          if (optionsObj && optionsObj.hasOwnProperty('options')) {
            this.setInitialOptionQty(optionsObj.options)
          }
        }
      }
      if (response && this.isPaymentPage && this.participant) {
        this.$data.formData[0].current_value = this.$data.formData[0].options[1].uuid
        await this.fetchLeadsOrderFees()
        if (this.paymentForm && this.paymentForm.processor === 'stripe') {
          await this.fetchStripeToken()
        }
        if (this.paymentForm && this.paymentForm.processor === 'authnet') {
          this.loadAuthnet()
        }
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
      this.$store.dispatch('common/hideLoader')
    }
  }

  @Watch('option_groups')
  onOptionGroupsChange() {
    if (Array.isArray(this.form?.pageData?.fields) && this.participant?.selected_option_qty) {
      this.loadFormData()
    }
  }

  setInitialOptionQty(options: any[]): void {
    const data = {} as any;
    for (let i = 0; i < options.length; i++) {
      data[options[i].uuid] = options[i].qty
    }
    Container.get(GtrStorage).setItem(`${this.eventIdentifier}_initial_lr_devices`, JSON.stringify(data))
  }

  validateDeviceQuantityFields() {
    this.deviceQuantities.forEach(field => {
      field.validate()
    })
  }

  handleActivationsChange(activationsSelection: string) {
    const lrDevices = this.$data.formData.find(field => field.field === 'lr_devices')
    const activationOptions = lrDevices.options.filter(option => ['Lead Retrieval Activations: 3', 'Lead Retrieval Activations: 5', 'Lead Retrieval Activations: Unlimited'].includes(option.name))
    for (const option of activationOptions) {
      option.qty = 0
    }
    if (activationsSelection) {
      const selectedOption = activationOptions.find(option => option.uuid === activationsSelection)
      if (selectedOption) {
        selectedOption.qty = 1
      }
    }
  }

  optionsToDisplayOnForm(options: any[]) {
    if (this.use_legacy_leads_pricing) {
      return options.filter(option => ['proscanner', 'lead retrieval mobile app'].includes(option.name.toLowerCase()))
    } else {
      return options
    }
  }

  /**
   * Copy form fields from store to data, and copy option groups into relevant fields.
   */
  loadFormData(): void {
    const pageFields = JSON.parse(JSON.stringify(this.form.pageData.fields))
    for (let i = 0; i < pageFields.length; i++) {
      const field = pageFields[i]
      if (field.option_group_uuid) {
        field.options = this.setupOptions(field.option_group_uuid)
      }
      if (!field.current_value) {
        if (field.type === 'checkbox' && field.options?.length > 1) {
          // Initialize checkbox group values
          field.current_value = []
          for (let i = 0; i < field.options.length; i++) {
            if (field.options[i].qty) {
              field.current_value.push(field.options[i].name)
            }
          }
        } else if (field.type === 'select' && field.options?.length > 1) {
          // Initialize checkbox group values
          field.current_value = null
          for (let i = 0; i < field.options.length; i++) {
            if (field.options[i].qty) {
              field.current_value = field.options[i].uuid
            }
          }
        } else {
          // Initialize checkbox value
          field.current_value = field.options?.length === 1 && field.options[0].qty ? field.options[0].name : null
        }
      }
    }
    this.$data.formData = pageFields
    this.$data.dataLoaded = true
  }

  /**
   * This method will compile the options for a given option group.
   * @param {string} option_group_uuid - the uuid option group to interogate
   * @param {any} field - Optional field
   * @returns the list of options or false.
   */
  setupOptionsFull(option_group_uuid: string, field?: any) {
    // Option_groups aren't loaded, so return an empty array to this method
    if (this.option_groups.length === 0) return []

    const optionsArray: any = []
    if (field && field.field === 'pay_for_another') {
      optionsArray.push({text: 'Yes', value: 'Yes'})
      optionsArray.push({text: 'No', value: 'No'})
      return optionsArray
    }
    let option_group = this.option_groups.filter((group: any) => {
      return group.uuid === option_group_uuid
    })
    /**
     * If option_group hasn't been called yet by api, return false to prevent console error
     */
    if (option_group.length === 0) {
      return false
    }
    option_group = option_group.shift()
    if (option_group.blank_first === 1) {
      optionsArray.push({text: '', value: ''})
    }

    if (option_group.group_display) {
      field.visible = true
    } else {
      field.visible = false
    }

    const options: any = option_group.options
    // check for autohide setting. default to false.
    const autohideEnabled = false;

    for (let i = 0; i < options.length; i++) {
      // if option is set to display false, skip it.
      const {cap, count} = options[i];
      const capReached = (autohideEnabled && cap !== null && count === cap)
      if (!options[i].visible || capReached) continue;
      const price = options[i].pricing[0].tiered_pricing['_default'].price ?? 0
      optionsArray.push({
        text: options[i].name + " - $" + price,
        value: options[i].uuid,
      })
    }
    return optionsArray
  }

  /**
   * Get copies of the visible options of an option group with only relevant properties.
   * @param option_group_uuid
   * @returns Array of option objects.
   */
  setupOptions(option_group_uuid: string) {
    let option_groups: any = this.option_groups
    let options = []
    const optionsArray: any = []

    // Get visible options
    if (Array.isArray(option_groups) && option_groups.length) {
      option_groups = option_groups.filter((group: any) => group.uuid === option_group_uuid)
      options = option_groups[0].options.filter((option: any) => !!option.visible)
    }

    // Copy relevant properties into new array
    for (let i = 0; i < options.length; i++) {
      const option: any = options[i]
      const initial_qty = this.participant.selected_option_qty[option_group_uuid]?.[option.uuid] || 0
      const qty_remaining = option.cap ? option.cap - (option.count ? option.count : 0) + initial_qty : null

      //TODO(zb): This is a temporary measure for the MicroScanner option. It should be removed once the backend is updated.
      // The MicroScanner is an option from the Option Group.
      // Some older events will have MicroScanner as an option due to how events are created on the platform.
      // @Zachary Berwaldt in slack for more information.
      if (option.name === 'MicroScanner') continue

      optionsArray.push({
        details: option.details.en,
        name: option.name,
        option_group_uuid: option_group_uuid,
        pricing: option.pricing,
        current_pricing_tier: option.current_pricing_tier,
        qty_remaining: qty_remaining,
        qty: initial_qty,
        uuid: option.uuid,
        value: null
      })
    }

    return optionsArray
  }

  detailsWithPricing(option: any): string {
    try {
      const pricing = option.pricing[0].tiered_pricing
      let details = option.details
      const optionPricingTierUuid = option?.current_pricing_tier
      if (option.name === 'Lead Retrieval Mobile App' && !this.use_legacy_leads_pricing) {
        details = details.replace('Only {basePrice}', '')
        details = details.replace('Each additional app: {additionalPrice}', '')

      } else if (pricing?._default) {
        // Default pricing from the back end
        details = details.replace('{basePrice}', '$' + option.pricing[0].tiered_pricing._default.qty_pricing['1'])
        details = details.replace('{additionalPrice}', '$' + option.pricing[0].tiered_pricing._default.qty_pricing['2-1000'])
      } else if (option.pricing[0].reg_type === '_default' && optionPricingTierUuid) {
        details = details.replace('{basePrice}', '$' + (option?.pricing?.[0]?.tiered_pricing?.[optionPricingTierUuid]?.qty_pricing?.['1'] ?? 0))
        details = details.replace('{additionalPrice}', '$' + option?.pricing?.[0]?.tiered_pricing?.[optionPricingTierUuid]?.qty_pricing?.['2-1000'])
      } else {
        for (const key in pricing) {
          // Use the first price property found. For now we're just expecting a single price tier.
          if (key !== 'uuid') {
            details = details.replace(/{basePrice}|{additionalPrice}/g, '$' + pricing[key].price)
            break
          }
        }
      }
      return details
    } catch (error) {
      return option.details
    }
  }

  initializeEditFormData(formData: any[]): void {
    for (const currentField of formData) {
      if (currentField?.field === 'lr_devices' && Array.isArray(currentField?.options)) {
        const initialLRDevices = JSON.parse(Container.get(GtrStorage).getItem(`${this.eventIdentifier}_initial_lr_devices`))
        for (const option of currentField.options) {
          Vue.set(this.$data.editFormData, option.uuid, 0)
          if (this.isStatusComplete && initialLRDevices && initialLRDevices[option.uuid] >= 0) {
            this.$data.editFormData[option.uuid] = option.qty - initialLRDevices[option.uuid]
          }
        }
      }
    }
  }

  showIf(field: any): boolean {
    if (field.field === 'lr_which_os') {
      return this.isMobileAppSelected
    }

    if (field.field === 'lr_ios_devices') {
      return this.isMobileAppSelected && this.isIosSelected
    }

    return true
  }

  trackSelection(field: any) {
    if (field.field === 'lr_payment_method' && Array.isArray(field.options)) {
      for (let i = 0; i < field.options.length; i++) {
        const option = field.options[i]
        if (option.name === 'Credit Card') {
          if (option.uuid === field.current_value) {
            this.$data.creditSelected = true
          }
          if (option.uuid !== field.current_value) {
            this.$data.creditSelected = false
          }
        }
      }
    }
    return true
  }

  async fetchParticipant() {
    try {
      const payload = {
        event_identifier: this.eventIdentifier
      }
      await this.$store.dispatch('auth/getParticipant', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async fetchLeadsOrderFees() {
    try {
      const payload = {
        event_identifier: this.eventIdentifier,
        participant_uuid: this.participant.uuid
      }
      await this.$store.dispatch('leads/getLeadsOrderFees', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async fetchStripeToken() {
    try {
      const payload = {
        event_identifier: this.eventIdentifier,
        pagenum: this.currentPage,
        widget: this.paymentWidget
      }
      await this.$store.dispatch('payment/startStripeTokenLeads', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  loadAuthnet() {
    if (!document.querySelector('#authnet_script') && this.paymentForm) {
      const authnet_script = document.createElement('script')
      const authnet_script_url = this.paymentForm.processorData.endpoint
      if (authnet_script_url) {
        authnet_script.setAttribute('src', authnet_script_url)
        authnet_script.setAttribute('id', 'authnet_script')
        authnet_script.async = true
        document.head.appendChild(authnet_script)
      }
    }
  }

  toggleError(event: any) {
    const errorMessages: any = {
      incomplete_number: 'The card number is incomplete.',
      invalid_number: 'The card number is not a valid credit card number.',
      invalid_expiry_month: 'The card\'s expiration month is invalid.',
      invalid_expiry_year: 'The card\'s expiration year is invalid.',
      incomplete_expiry: 'The expiration date of your card is incomplete.',
      invalid_cvc: 'The card\'s security code is invalid.',
      expired_card: 'The card has expired.',
      incorrect_cvc: 'The card\'s security code is incorrect.',
      incorrect_zip: 'The card\'s zip code failed validation.',
      card_declined: 'The card was declined.',
      missing: 'There is no card on a customer that is being charged.',
      processing_error: 'An error occurred while processing the card.',
      rate_limit: 'An error occurred due to requests hitting the API too quickly. Please let us know if you\'re consistently running into this error.',
      incomplete_cvc: 'The security code for your card is incomplete.',
      invalid_expiry_year_past: 'The card\'s expiration year is invalid.',
      incomplete_zip: 'Your zip code is incomplete.'
    }
    if (event.error) {
      this.$data.stripeError = errorMessages[event.error.code]
    } else {
      this.$data.stripeError = ''
    }
  }

  /**
   * NAVIGATION AND FORM PROCESSING
   */

  goBack() {
    if (this.previousPage >= 0) {
      this.$router.push(`/${this.eventIdentifier}/leads/order/${this.previousPage}`)
    }
  }

  goToPage(pageNumber: number): void {
    this.$router.push(`/${this.eventIdentifier}/leads/order/${pageNumber}`)
  }

  /**
   * Process form
   * 1) handleFieldProcessing()
   * 2) handleBulkUpdate()
   * 3) isPaymentPage ? submitPayment() then submit
   * 4) else submit()
   */
  async processForm(goToReceiptPage = false) {
    try {
      this.$data.formProcessDialog = true
      this.$data.submitting = true
      const optionGroupFields = this.$data.formData.filter(field => field.option_group_uuid)
      const fields = this.$data.formData.filter(field => !field.option_group_uuid)
      this.$data.optionGroupSelections = this.handleOptionGroupProcessing(optionGroupFields)
      this.$data.pageSubmissionData = this.handleFieldProcessing(fields, goToReceiptPage)
      await this.handleBulkUpdate()
      const tokenizedData: any = {
        payment_details: {}
      }
      if (this.isPaymentPage && this.$data.creditSelected) {
        this.$data.paymentDialog = true
        if (this.paymentForm && this.paymentForm.processor === 'stripe') {
          const self = this
          if (this.transaction_type === 'vault') {
            this.$data.stripe.confirmCardSetup(this.stripeIntent, {
              payment_method: {
                card: this.$data.card,
                billing_details: {
                  name: `${this.$data.credit_first_name} ${this.$data.credit_last_name}`
                }
              }
            }).then(function (result: any) {
              if (result.error) {
                Container.get(ErrorHandlerService).error(result.error.message)
              } else {
                const pm = result.setupIntent.payment_method
                tokenizedData.payment_details.stripePaymentId = pm
                tokenizedData.payment_details.first_name = self.$data.credit_first_name
                tokenizedData.payment_details.last_name = self.$data.credit_last_name
                tokenizedData.payment_details.address = self.$data.credit_address
                tokenizedData.payment_details.city = self.$data.credit_city
                tokenizedData.payment_details.zip = self.$data.credit_zip
                tokenizedData.payment_details.country = self.$data.credit_country
                tokenizedData.page_number = self.currentPage
                tokenizedData.currentLanguage = self.currentLanguage
                tokenizedData.event_identifier = self.eventIdentifier
                tokenizedData.widget_id = self.paymentWidget
                tokenizedData.transaction_type = self.transaction_type
                tokenizedData.line_items = self.lr_order_fees.line_items
                if (self.$data.credit_country === 'United States') {
                  tokenizedData.payment_details.state = self.$data.credit_state
                } else if (self.$data.credit_country === 'Canada') {
                  tokenizedData.payment_details.province = self.$data.credit_province
                }
                self.submitPayment(tokenizedData)
              }
            }.bind(this))
          } else {
            const result = await this.$data.stripe.createToken(this.$data.card)
            tokenizedData.payment_details.stripeToken = result?.token?.id
            tokenizedData.payment_details.first_name = this.$data.credit_first_name
            tokenizedData.payment_details.last_name = this.$data.credit_last_name
            tokenizedData.payment_details.address = this.$data.credit_address
            tokenizedData.payment_details.city = this.$data.credit_city
            tokenizedData.payment_details.zip = this.$data.credit_zip
            tokenizedData.payment_details.country = this.$data.credit_country
            tokenizedData.page_number = this.currentPage
            tokenizedData.currentLanguage = this.currentLanguage
            tokenizedData.event_identifier = this.eventIdentifier
            tokenizedData.widget_id = this.paymentWidget
            tokenizedData.transaction_type = this.transaction_type
            tokenizedData.line_items = this.lr_order_fees.line_items
            if (this.$data.credit_country === 'United States') {
              tokenizedData.payment_details.state = this.$data.credit_state
            } else if (this.$data.credit_country === 'Canada') {
              tokenizedData.payment_details.province = this.$data.credit_province
            }
            this.submitPayment(tokenizedData)
          }
        }
        if (this.paymentForm && this.paymentForm.processor === 'authnet') {
          const secureData = {
            cardData: {
              cardNumber: this.$data.credit_card_number,
              month: this.$data.credit_expiration_month,
              year: this.$data.credit_expiration_year,
              cardCode: this.$data.credit_cvv
            },
            authData: {
              clientKey: this.paymentForm.processorData.tokens.public_client_key,
              apiLoginID: this.paymentForm.processorData.tokens.api_login_id
            }
          }
          if ((window as any).Accept) {
            const Accept = (window as any).Accept
            Accept.dispatchData(secureData, (response: any) => {
              if (response.messages.resultCode === 'Error') {
                if (response.messages.message) {
                  const message = response.messages.message[0].text
                  Container.get(Notification).error(`Authnet error: ${message}`)
                } else {
                  Container.get(ErrorHandlerService).error('Payment error')
                }
                this.$data.paymentDialog = false
              } else {
                tokenizedData.payment_details.dataDescriptor = response.opaqueData.dataDescriptor
                tokenizedData.payment_details.dataValue = response.opaqueData.dataValue
                tokenizedData.payment_details.expiration_month = this.$data.credit_expiration_month
                tokenizedData.payment_details.expiration_year = this.$data.credit_expiration_year
                tokenizedData.payment_details.first_name = this.$data.credit_first_name
                tokenizedData.payment_details.last_name = this.$data.credit_last_name
                tokenizedData.payment_details.address = this.$data.credit_address
                tokenizedData.payment_details.city = this.$data.credit_city
                tokenizedData.payment_details.zip = this.$data.credit_zip
                tokenizedData.payment_details.country = this.$data.credit_country
                tokenizedData.page_number = this.currentPage
                tokenizedData.currentLanguage = this.currentLanguage
                tokenizedData.event_identifier = this.eventIdentifier
                tokenizedData.widget_id = this.paymentWidget
                tokenizedData.transaction_type = this.transaction_type
                tokenizedData.line_items = this.lr_order_fees.line_items
                if (this.$data.credit_country === 'United States') {
                  tokenizedData.payment_details.state = this.$data.credit_state
                } else if (this.$data.credit_country === 'Canada') {
                  tokenizedData.payment_details.province = this.$data.credit_province
                }
                this.submitPayment(tokenizedData)
              }
            })
          }
        }
      } else {
        await this.submit()
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.formProcessDialog = false
      this.$data.submitting = false
    }
  }

  /**
   * Builds page submissionData object and optionGroupFields object
   * Calls handleBulkUpdate function, submit function, and submitPayment function
   * @param goToReceiptPage
   */
  handleOptionGroupProcessing(fields: any[]) {
    const optionGroupFields: any = {
      event_identifier: this.eventIdentifier,
      selections: {}
    }
    for (const field of fields) {
      optionGroupFields.selections[field.option_group_uuid] = {}
      for (const option of (field?.options || [])) {
        if (field.type === 'qty_select') {
          if (option.qty || this.isStatusComplete) {
            if (!this.isStatusComplete || ['Lead Retrieval Activations: 3', 'Lead Retrieval Activations: 5', 'Lead Retrieval Activations: Unlimited'].includes(option.name)) {
              optionGroupFields.selections[option.option_group_uuid][option.uuid] = {qty: parseInt(option.qty)}
            } else {
              const initialLRDevices = JSON.parse(Container.get(GtrStorage).getItem(`${this.eventIdentifier}_initial_lr_devices`))
              optionGroupFields.selections[option.option_group_uuid][option.uuid] = {qty: (this.$data.editFormData[option.uuid] + (initialLRDevices ? initialLRDevices[option.uuid] : 0))}
            }
          }
        } else if (field.type === 'select') {
          if (field.current_value === option.uuid) {
            optionGroupFields.selections[option.option_group_uuid][option.uuid] = {qty: 1}
          }
        } else if (field.type === 'checkbox' && this.showIf(field)) {
          if (Array.isArray(field.current_value)) {
            if (field.current_value.includes(option.name)) {
              optionGroupFields.selections[option.option_group_uuid][option.uuid] = {qty: 1}
            }
          } else {
            // Single checkbox
            if (field.current_value !== null && field.current_value !== '') {
              optionGroupFields.selections[option.option_group_uuid][option.uuid] = {qty: 1}
            }
          }
        }
      }
    }
    return optionGroupFields
  }

  handleFieldProcessing(fields: any[], goToReceiptPage: boolean) {
    const submissionData: any = {
      event_identifier: this.eventIdentifier,
      page_number: this.currentPage,
      last_viewable_reg_page: this.lastViewableRegPage,
      currentLanguage: this.currentLanguage,
      goToReceiptPage: goToReceiptPage
    }
    for (const field of fields) {
      submissionData[field.field] = field.current_value ? field.current_value : null
    }
    return submissionData
  }

  /**
   * Submits option selections to backend
   */
  async handleBulkUpdate() {
    try {
      this.$data.optionGroupSelections.lr_orders = true
      await this.$store.dispatch('options/bulkOptionsUpdate', this.$data.optionGroupSelections)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  /**
   * Submit stripe payment to backend
   * @param tokenizedData
   */
  async submitPayment(tokenizedData: any) {
    try {
      await this.$store.dispatch('leads/submitLeadsOrderPayment', tokenizedData)
      this.$data.paymentDialog = false
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  /**
   * Form submission to backend
   */
  async submit() {
    try {
      const response = await this.$store.dispatch('leads/submitLeadsOrderForm', this.$data.pageSubmissionData)
      const nextPage = response.data.next_page
      this.$router.push(`/${this.eventIdentifier}/leads/order/${nextPage}`)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Watch('finishedPayment')
  onFinishedPaymentChange(payload: any) {
    if (payload) {
      this.submit().catch(function (error: any) {
        Container.get(ErrorHandlerService).error(error)
      })
    }
  }

  @Watch('form')
  async onFormChange(payload: any) {
    if (payload) {
      const page = payload
      if (page.pageData.page_type === 'RECEIPT') {
        Container.get(GtrStorage).removeItem(`${this.eventIdentifier}_initial_lr_devices`)
        /**
         * Change the url without reloading the page via vue router
         */
        window.history.pushState(null, '', '/' + this.eventIdentifier + '/leads/order/receipt')

        try {
          await this.$store.dispatch('leads/receipt', {
            event_identifier: this.eventIdentifier,
            pagenum: this.currentPage
          })
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        }
      }
    }
  }

  /**
   *
   * @param dateToFormat : string in the format yyyy-mm-dd hh:mm
   * @returns : date
   */
  convertUtcStringToDate(dateToFormat: string): Date {
    return new Date(dateToFormat.replace(' ', 'T') + ':00.000Z')
  }

  private sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

// end
}
